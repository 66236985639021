import {
  Anchor,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Title,
} from "@flpstudio/design-system";
import { format } from "date-fns-tz";
import parseHtml from "html-react-parser";
import DOMPurify from "isomorphic-dompurify";
import { useState } from "react";

import { ampli } from "@/ampli";
import type { AssessmentDto, QuizAnswerDto } from "@/api/openapi";
import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { TextOverflowWrapper } from "@/components/atoms/TextOverflowWrapper";
import { ComponentError } from "@/components/organisms/Error/ComponentError";
import { TypeFormQuiz } from "@/components/organisms/ITRecommendation/TypeFormQuiz";
import { useUserITAssessments } from "@/hooks/use-user";

function QuestionAndAnswer(props: QuizAnswerDto) {
  return (
    <>
      <dt>
        <TextOverflowWrapper>
          {parseHtml(
            DOMPurify.sanitize(
              props.question.replaceAll(
                /\*[^*]+\*/g,
                (str) =>
                  `<b class="font-semibold">${str.replaceAll("*", "")}</b>`,
              ),
              {
                ALLOWED_TAGS: ["b"],
              },
            ),
          )}
        </TextOverflowWrapper>
      </dt>
      {props.answerType === "array" && (
        <dd>
          <ul className="m-0 list-none p-0">
            {props.arrayAnswer?.map((answer) => (
              <li key={answer}>
                <TextOverflowWrapper>
                  {parseHtml(DOMPurify.sanitize(answer))}
                </TextOverflowWrapper>
              </li>
            ))}
          </ul>
        </dd>
      )}
      {props.answerType === "string" && (
        <dd>
          <TextOverflowWrapper>
            {parseHtml(DOMPurify.sanitize(props.stringAnswer || ""))}
          </TextOverflowWrapper>
        </dd>
      )}
      {props.answerType === "number" && (
        <dd>
          <TextOverflowWrapper>
            {parseHtml(DOMPurify.sanitize(`${props.numberAnswer}`))}
          </TextOverflowWrapper>
        </dd>
      )}
    </>
  );
}

function AssessmentDetails(props: AssessmentDto) {
  const HIDDEN_QUESTIONS = new Set([
    "first name",
    "last name",
    "email address",
    "company name",
    "country",
  ]);

  return (
    <dl className="m-0 [&_dd:not(:last-of-type)]:mb-4 [&_dd]:ml-0 [&_dd]:text-[--mantine-color-gray-6]">
      {props.answers
        .filter((answer) => {
          const isQuestionHidden = HIDDEN_QUESTIONS.has(
            answer.question.toLowerCase(),
          );

          if (isQuestionHidden) {
            return false;
          }

          return (
            Boolean(answer.stringAnswer) ||
            Boolean(answer.arrayAnswer) ||
            Boolean(answer.numberAnswer)
          );
        })
        .map((answer) => (
          <QuestionAndAnswer key={answer.question} {...answer} />
        ))}
    </dl>
  );
}

export function ITAssessment() {
  const { data, isLoading, isError } = useUserITAssessments();

  const [selectedAssessment, setSelectedAssessment] =
    useState<AssessmentDto | null>(null);

  if (isLoading) {
    return <Skeleton repeat={5} />;
  }

  if (isError) {
    return <ComponentError />;
  }

  const latestAssessment = data?.[0];
  const previousAssessments = data?.slice(1) || [];

  return (
    <>
      <Group className="mb-4 justify-between">
        <Title order={2} className="text-xl/normal">
          IT assessment
        </Title>
        <TypeFormQuiz
          buttonLabel="CLIENT_ASSESSMENT"
          onClick={() => ampli.clientProfileClickTakeQuiz()}
        />
      </Group>
      <Stack>
        {latestAssessment ? (
          <AssessmentDetails {...latestAssessment} />
        ) : (
          <Text className="text-[--mantine-color-gray-6]">
            You haven’t completed an IT assessment yet. Taking the assessment
            will allow GuideStack to recommend more tailored guides and experts,
            while also helping experts better understand your IT challenges to
            provide more effective support.
          </Text>
        )}
        {previousAssessments.length ? (
          <>
            <Title order={3} className="text-base/normal">
              Previous assessments
            </Title>
            <ul className="m-0 list-none p-0 [&_li:not(:last-child)]:mb-4">
              {previousAssessments.map((assessment) => (
                <li key={assessment.id}>
                  <Anchor
                    component="button"
                    className="underline"
                    onClick={() => {
                      setSelectedAssessment(assessment);
                      ampli.clientProfileClickPastQuiz();
                    }}
                  >
                    {format(assessment.quizTime, "MMMM dd, yyyy HH:mm")}
                  </Anchor>
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </Stack>
      <Modal
        opened={Boolean(selectedAssessment)}
        onClose={() => setSelectedAssessment(null)}
        title="Assessment responses"
      >
        <Stack>
          {selectedAssessment ? (
            <AssessmentDetails {...selectedAssessment} />
          ) : null}
          <div className="-mb-6 sticky bottom-0 z-10 flex flex-col bg-white pb-6 lg:flex-row-reverse">
            <Button
              variant="outline"
              onClick={() => setSelectedAssessment(null)}
            >
              Close
            </Button>
          </div>
        </Stack>
      </Modal>
    </>
  );
}
